import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import SelectOptions from "dto/app/selectoptions.dto";
import { CommonTools } from "tools/utils/commontools";

export class CourseDto implements Idto {
  id?: string;
  name?: string;
  description?: string;
  status?: number;

  idcoursesettings?: string;
  idlanguage?: string;

  idteacher?: string;
  courseduration?: number;

  teacher?: any;
  courseprice?: any;
  certificate?: any;

  popularidentifier?: number;
  requirementstext?: string;
  whatwillyoulearntext?: string;
  targetaudiencetext?: string;

  numberoflessons?: number;
  shortdescription?: string;
  coursesettings?: any;

  gallery?: any;
  attachment?: any;
  video?: any;

  createddate?: number;
  updateddate?: number;

  constructor(
    id?: string,
    name?: string,
    description?: string,
    status?: number,
    idlanguage?: string,
    idteacher?: string,
    courseduration?: number,
    popularidentifier?: number,
    requirementstext?: string,
    whatwillyoulearntext?: string,
    targetaudiencetext?: string,
    numberoflessons?: number,
    shortdescription?: string
  ) {
    this.status = status || Status.ACTIVE;
    this.popularidentifier = popularidentifier || 0;
    this.id = id || "";
    this.name = name || "";
    this.description = description || "";
    this.idlanguage = idlanguage || "";
    this.idteacher = idteacher || "";
    this.courseduration = courseduration || 0;
    this.requirementstext = requirementstext || "";
    this.whatwillyoulearntext = whatwillyoulearntext || "";
    this.targetaudiencetext = targetaudiencetext || "";
    this.numberoflessons = numberoflessons || 0;
    this.shortdescription = shortdescription || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "createddate",
        value: ["createddate_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "updateddate",
        value: ["updateddate_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Description",
        value: ["description"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "requirementstext",
        value: ["requirementstext"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "whatwillyoulearntext",
        value: ["whatwillyoulearntext"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "targetaudiencetext",
        value: ["targetaudiencetext"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "shortdescription",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "teacher",
        value: ["teacher", "fullname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "CourseDuration",
        value: ["courseduration"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Numberoflessons",
        value: ["numberoflessons"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PopularIdentifier",
        value: ["popularidentifier"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL,"createddate"),
      ComponentCommonTools.columns_ConstantColumn(LL,"updateddate"),
      ComponentCommonTools.columns_StandardColumn(LL, "popularidentifier"),
      ComponentCommonTools.columns_StandardColumn(LL, "numberoflessons"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "teacher",
        "fullname"
      ),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "language", "name"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        [
          "edit",
          "detail",
          "delete",
          "gallery",
          "attachment",
          "video",
          "courseenrollment",
          "linkcoursecategory",
          "coursefilter",
          "lesson",
          "test"
        ],
        ["detail", "attachment"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("course", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<CourseDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(CourseDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: CourseDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["name"])
    );
  };
}

export class PostCourseDto implements Idto {
  name?: string;
  description?: string;
  status?: number;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  idcoursesettings?: string;
  idlanguage?: string;
  idslesson?: string[];
  idscoursecategory?: string[];
  idscoursefilter?: string[];
  idteacher?: string;
  courseduration?: number;
  teacher?: any;
  certificate?: any;
  popularidentifier?: number;
  requirementstext?: string;
  whatwillyoulearntext?: string;
  targetaudiencetext?: string;
  numberoflessons?: number;
  shortdescription?: string;

  constructor() {
    this.status = Status.ACTIVE;
    this.popularidentifier = 0;
  }
}
