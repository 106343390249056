import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import RequestListDTO from "dto/app/requestlist.dto";

import { GridLabelDto, LabelDto } from "dto/static/label.dto";
import { useList } from "hooks/useList";
import { IPage } from "interfaces/page.interface";
import React, { useState } from "react";
import { LabelService } from "services/static/label.service";
import { GridModeItem } from "./GridModeItem";
import { RouteTools } from "tools/utils/routetools";
import { CommonTools } from "tools/utils/commontools";

import { TableToolbar } from "components/elements/tabeltools/TableToolbar";
import { CircularLoading } from "components/elements/loading/CircularLoading";

import { Sorter } from "components/elements/select/newSelect/Sorter";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { GridPagination } from "./GridPagination";

import { MessagesContainerPopUpConfirmDelete } from "components/message/MessagesContainerPopUpConfirmDelete";
import { LooksOne, LooksTwo } from "@mui/icons-material";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import LooksTwoOutlinedIcon from "@mui/icons-material/LooksTwoOutlined";

import { useResource } from "hooks/useResource";
import { useCookie } from "hooks/useCookie";

const service = new LabelService();
const GridMode: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { setCookieValue, cookies, getCookie } = useCookie();
  const { LL } = useResource();

  const defaultRequest = service.getDefaultRequestList(
    RouteTools.prepareListRequest(currentRoute, cookies)
  );
  const [page, setPage] = useState(defaultRequest.page ?? 1);
  const [onPage, setOnPage] = useState(defaultRequest.onpage ?? 10);
  const [sortCriteria, setSortCriteria] = useState(
    defaultRequest.sortcriteria ?? []
  );
  const [filters, setFilters] = useState(defaultRequest.filters ?? []);
  const mainUrl = CommonTools.generateMainUrl(mainObject);
  const getList = (cb: any, cbParams: any, reqList: any) => {
    const urlPart = RouteTools.prepareListLocation(reqList);
    RouteTools.setHistory(mainUrl + urlPart, {});
    service.getListAllValues(cb, cbParams, reqList);
  };

  const processSetOnPage = (value: number) => {
    setOnPage(value);
    setPage(1);
  };
  const processSetSortCriteria = (object: RequestSortCriteriaDTO | null) => {
    if (!object) return;
    setSortCriteria([object]);
  };
  const [loading, objects, , totalPages, , getListFun] = useList<GridLabelDto>(
    getList,
    service.getDefaultRequestList(
      new RequestListDTO(filters, page, onPage, sortCriteria)
    ),
    [page, onPage, sortCriteria, filters]
  );

  const handleDelete = (item: GridLabelDto) => {
    if (!item) return;
    if (!item.hasOwnProperty("id")) return;
    if (!item.id) return;
    service.delete(
      CommonTools.processObjectField(item, ["id"]),
      getListFun,
      {}
    );
  };
  const [deleteObject, setDeleteObject] = useState<GridLabelDto | undefined>(
    undefined
  );
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [alignment, setAlignment] = useState(
    getCookie("alignment_mode", "1fr")
  );
  const [selected, setSelected] = useState(false);

  const setObjectToDelete = (object: GridLabelDto) => {
    setDeleteObject(object);
    setOpenConfirmDelete(true);
  };
  const handleConfirmDelete = () => {
    if (!deleteObject) return;
    handleDelete(deleteObject);
    setOpenConfirmDelete(false);
    setDeleteObject(undefined);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDelete(false);
    setDeleteObject(undefined);
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => {
    setSelected(!selected);
    setCookieValue("alignment_mode", value);
    setAlignment(value);
  };

  if (loading) return <CircularLoading />;
  if (!objects) return null;
  return (
    <Box>
      <Box className="headerToolBarLabels">
        <Box className="headerToolBarLabelsLeft">
          <Box className="labelsGridTopBar">
            <Typography> {LL("labelsGridTopBar")}</Typography>
            <ToggleButtonGroup
              className="buttonToggleColumn"
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="grid_alignment">
              <ToggleButton
                value="repeat(auto-fill, minmax(0, 780px))"
                aria-label="grid_one_aligned">
                {selected ? <LooksOneOutlinedIcon /> : <LooksOne />}
              </ToggleButton>
              <ToggleButton
                value="repeat(2, minmax(0, 780px))"
                aria-label="grid_two_aligned">
                {selected ? <LooksTwo /> : <LooksTwoOutlinedIcon />}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box>
            <Sorter
              sortCriteria={sortCriteria[0] ?? null}
              setSortCriteria={processSetSortCriteria}
              values={["identifier"]}
            />
          </Box>
          <Box>
            <TableToolbar
              setFilter={setFilters}
              filters={filters}
              tbFilterList={LabelDto.getFilters()}
              setPage={setPage}
            />
          </Box>
        </Box>
        <Box className="paginationGrid">
          <GridPagination
            onPage={onPage}
            page={page}
            setPage={setPage}
            setOnPage={processSetOnPage}
            totalPages={totalPages}
          />
        </Box>
      </Box>
      <Box className="gridLabelsBackground">
        <Box sx={{}}>
          <Box
            className="gridLabelsBackgroundColumns"
            sx={{
              gridTemplateColumns: {
                xs: "repeat(auto-fill, minmax(0, 780px))",
                xl: alignment,
              },
            }}>
            {objects.map((object, index) => (
              <GridModeItem
                key={index}
                item={object}
                setObjectToDelete={setObjectToDelete}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box className="paginationGridBottom">
        <GridPagination
          onPage={onPage}
          page={page}
          setPage={setPage}
          setOnPage={processSetOnPage}
          totalPages={totalPages}
        />
      </Box>
      <MessagesContainerPopUpConfirmDelete
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        showField={"identifier"}
        _obj={deleteObject}
      />
    </Box>
  );
};

export { GridMode };
