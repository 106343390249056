import React, { useState } from "react";
import  MyCKEditor  from "./MyCKEditor";
import { Box, ButtonGroup, Typography } from "@mui/material";
import { Params } from "dto/system/params.dto";
import { MyButton } from "../button/MyButton";
import { useResource } from "hooks/useResource";
import { CommonTools } from "tools/utils/commontools";


type Props = {
  field: string;
  data?: string;
  setObjectField: (field: string, value: any) => void;
  params ?: Array<Params>;
};

const MyCKEditorWithParams: React.FC<Props> = ({
  data,
  field,
  setObjectField,
  params = [],
}) => {
  const { LL } = useResource();
  params = Array.isArray(params) ? params : [];
  
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const onClick = (item: Params) => {
    if (editorInstance) {
      editorInstance.model.change((writer: any) => {
        const selection = editorInstance.model.document.selection;
        const insertPosition = selection.getFirstPosition();

        // Insert the text at the current cursor position
        writer.insertText(item.key, insertPosition);

        // Move the selection to the end of the inserted text
        const endPosition = insertPosition.getShiftedBy(item.key.length);
        writer.setSelection(endPosition);

        // Refocus the editor
        editorInstance.editing.view.focus();
      });
    }
  };
  const processItem = (item: Params, index: number) => {
    return (
      <MyButton key={index} onClick={onClick.bind(this, item)} color="primary">
        {CommonTools.processObjectField(item, ["value"])}
      </MyButton>
    );
  };
  return (
    <Box
      maxWidth={"lg"}
      width={"100%"}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box width={"100%"} maxWidth={"sm"}>
        <MyCKEditor
          data={data ?? ""}
          field={field}
          setObj={setObjectField}
          onEditorReady={setEditorInstance}
        />
      </Box>
      <Box
        width={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box mb={1}>
          <Typography variant="h6">{LL("parameters")}</Typography>
        </Box>
        <ButtonGroup orientation="vertical" aria-label="Vertical button group">
          {params.map((item, index) => processItem(item, index))}
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export { MyCKEditorWithParams };
