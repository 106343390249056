import { Box, Stack, Typography } from "@mui/material";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";
import { CourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React from "react";
import { Status } from "tools/types/status";
import RequiredValidator from "validators/required.validator";
import { Types } from "tools/types/types";

type Props = {
  defaultObject: CourseTeacherRequestDto;
  handleReject: (data: CourseTeacherRequestDto) => void;
  handleAccept: (data: CourseTeacherRequestDto) => void;
  handleModify: (data: CourseTeacherRequestDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormCourseTeacherRequest: React.FC<Props> = ({
  defaultObject,
  handleReject,
  handleAccept,
  handleModify,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] =
    useForm<CourseTeacherRequestDto>(
      defaultObject,
      RequiredValidator.getValidators(["managermessage"])
    );

  const handleSubmitReject = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      handleReject(
        new CourseTeacherRequestDto(
          obj.id,
          obj.idteacher,
          undefined,
          obj.date,
          obj.status,
          undefined,
          obj.type,
          obj.managermessage,
          obj.teachermessage,
          obj.idcourse
        )
      );
    }
  };

  const handleSubmitAccept = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      handleAccept(
        new CourseTeacherRequestDto(
          obj.id,
          obj.idteacher,
          undefined,
          obj.date,
          obj.status,
          undefined,
          obj.type,
          obj.managermessage,
          obj.teachermessage,
          obj.idcourse
        )
      );
    }
  };

  const handleSubmitModify = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      handleModify(
        new CourseTeacherRequestDto(
          obj.id,
          obj.idteacher,
          undefined,
          obj.date,
          obj.status,
          undefined,
          obj.type,
          obj.managermessage,
          obj.teachermessage,
          obj.idcourse
        )
      );
    }
  };

  if (obj.status !== Status.UNPROCESSED) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flex: 1,
        }}
      >
        <Typography>{LL("RequestAlreadyProcessed")}</Typography>
      </Box>
    );
  }
  return (
    <Box>
      <form>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            multiline
            minRows={4}
            id="managermessage"
            name="managermessage"
            variant="outlined"
            color="primary"
            _label={LL("managermessage")}
            size="small"
            value={obj.managermessage}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 4 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <MyButton
            disabled={disabled || loading}
            color="error"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            cb={handleSubmitReject}
            children={LL("Bt_Reject")}
          />
          {obj.type === Types.TYPE_REQUEST_TEACHER_COURSE_VERIFY && (
            <MyButton
              disabled={disabled || loading}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              cb={handleSubmitModify}
            >
              {LL("Bt_Allow_to_publish")}
            </MyButton>
          )}
          <MyButton
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            cb={handleSubmitAccept}
            children={LL("Bt_Accept")}
          />
        </Stack>
      </form>
    </Box>
  );
};

export { FormCourseTeacherRequest };
