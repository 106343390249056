import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class AbuseDto implements Idto {
  id?: string;
  status?: number;
  type?: number;
  idmanager?: string;
  idstudent?: string;
  idteacher?: string;
  date?: number;
  responsedate?: number;
  message?: string;
  response?: string;
  iduser?: string;
  typesender?: number;
  idparent?: string;
  parent?: string;

  constructor(
    id?: string,
    status?: number,
    type?: number,
    idmanager?: string,
    idstudent?: string,
    idteacher?: string,
    date?: number,
    responsedate?: number,
    message?: string,
    response?: string,
    iduser?: string,
    typesender?: number,
    idparent?: string,
    parent?: string
  ) {
    this.id = id || "";
    this.status = status || Status.UNPROCESSED;
    this.type = type || Types.TYPE_VOCABULARY_ABUSE;
    this.idmanager = idmanager || "";
    this.idstudent = idstudent || "";
    this.idteacher = idteacher || "";
    this.date = date || 0;
    this.responsedate = responsedate || 0;
    this.message = message || "";
    this.response = response || "";
    this.iduser = iduser || "";
    this.typesender = typesender || Types.TYPE_SENDER_ADMIN;
    this.idparent = idparent || "";
    this.parent = parent || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Type",
        value: ["type_name"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Sender",
        value: ["typesender_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Date",
        value: ["date_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "response_date",
        value: ["responsedate_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "user_email",
        value: ["user", "email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "message",
        value: ["message"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "manager_email",
        value: ["manager", "email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "response",
        value: ["response"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_ConstantColumn(LL, "typesender"),
      ComponentCommonTools.columns_ConstantColumn(LL, "date"),
      ComponentCommonTools.columns_ConstantColumn(LL, "responsedate"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "user", "email"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "manager", "email"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editteacherrequest", "detail", "delete"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("requests", true));
    obj.addFilter(t);
    t = new TbFilterDTO("type", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Types.GA("abusetype", true));
    obj.addFilter(t);
    t = new TbFilterDTO("typesender", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("sendertype", true));
    obj.addFilter(t);

    return obj;
  }

  static processUrlObjectParent(obj: AbuseDto): string {
    const parent = CommonTools.processObjectField(obj, ["parent"]);
    if (!parent) return "";
    const idparent = CommonTools.processObjectField(obj, ["idparent"]);
    if (!idparent) return "";
    switch (parent) {
      case "teacherfaq": {
        return `/teacherfaq/${idparent}`;
      }
      default:
        return "";
    }
  }
}
