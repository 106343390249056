import { useState, useEffect, useMemo, useCallback } from "react";
import { ICurrentRoute } from "interfaces/currentroute.interface";
import { useGetUrlsList } from "./useGetUrlsList";
import Idto from "interfaces/idto.interface";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { useCookies } from "react-cookie";
import { CommonTools } from "tools/utils/commontools";
import { RouteTools } from "tools/utils/routetools";
import useObject from "./useObject";


type HasId = Idto & {
  id?: string | number;
};

type UseListAdminWithChildren = <T extends HasId>(
  mainObject: string | undefined,
  currentRoute: ICurrentRoute | null,
  getListFun: (cb: any, cbParams: any, reqList: RequestListDTO) => void,
  getRequestListFun: (req: RequestListDTO, idParent: string) => RequestListDTO,
  deleteFun: (id: string, cb?: any, params?: any) => void,
  getObjectFun: (id: string, cb: any, cbParams: any) => void,
  useDefaultApproach?: boolean
) => {
  mainUrl: string;
  addUrl: string;
  setListUrl: (url: string) => void;
  data: ResultListDTO;
  rows: Array<T>;
  total: number;
  totalPage: number;
  responseParams: RequestListDTO;
  reqList: RequestListDTO | null;
  loading: boolean;
  deleteOneObject: any;
  setDeleteOneObject: (obj: T | undefined) => void;
  handleDelete: (item?: T) => void;
  setReqList: (req: RequestListDTO) => void;
  getList: () => void;
  listUrl: string;
  loadingObject: boolean;
  object: T | null;
  setParent: (obj: T) => void;
  goUp: (e:any) => void;
  idParent: string;
};
var isRequesting = false;
const useListAdminWithChildren: UseListAdminWithChildren = <T extends HasId>(
  mainObject: string | undefined,
  currentRoute: ICurrentRoute | null,
  getListFun: (cb: any, cbParams: any, reqList: RequestListDTO) => void,
  getRequestListFun: (req: RequestListDTO, idParent: string) => RequestListDTO,
  deleteFun: (id: string, cb?: any, params?: any) => void,
  getObjectFun: (id: string, cb: any, cbParams: any) => void,
  useDefaultApproach?: boolean
) => {
  if (useDefaultApproach === undefined) useDefaultApproach = true;

  const [cookies] = useCookies();
  const { mainUrl, addUrl, setListUrl, listUrl } = useGetUrlsList(
    mainObject,
    currentRoute,
    useDefaultApproach
  );
  const getDefaultIdParent = (): string => {
    if (!currentRoute) return "";
    return RouteTools.getDefaultIdParent(currentRoute, cookies);
  };

  const [idParent, setIdParent] = useState<string>(getDefaultIdParent());
  const [id, setId] = useState<string>("");

  const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  const [rows, setRows] = useState<Array<T>>(new Array<T>());
  const [total, setTotal] = useState(-1);
  const [totalPage, setTotalPage] = useState(-1);
  const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
  );
  const [reqList, setReqList] = useState<RequestListDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteOneObject, setDeleteOneObject] = useState<T | undefined>(
    undefined
  );

  const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
      data,
      setData,
      setRows,
      setTotal,
      setTotalPage,
      setResponseParams
    );
    isRequesting = false;
  };

  const getList = useCallback(() => {
    if (!reqList) return;
    if (!CommonTools.atLeastOneFieldDefined(reqList)) return
    if (isRequesting) return;
    isRequesting = true;
    setLoading(true);
    if (useDefaultApproach) {
      const urlPart = RouteTools.prepareListLocation(reqList);
      RouteTools.setHistory(mainUrl + urlPart, {});
      setListUrl(mainUrl + urlPart);
    }
    getListFun(loadObjects, {}, reqList);
  }, [reqList]);

  const getReqList = () => {
    if (idParent !== id) {
      setId(idParent);
    }
    let reqListLocal = RouteTools.prepareListRequest(currentRoute, cookies);
    reqListLocal = CommonTools.addToRequestFilter(
      reqListLocal,
      "idparent",
      idParent
    );
    reqListLocal = getRequestListFun(reqListLocal, idParent);
    setReqList(reqListLocal);
  };

  const checkIsLoading = useCallback(() => {
    const load: boolean = CommonTools.checkIsLoading(
      reqList,
      responseParams,
      data,
      total,
      totalPage,
      rows
    );
    setLoading(load);
  }, [reqList, responseParams, data, total, totalPage, rows]);


  useEffect(() => {
    getReqList();
  }, []);

  useEffect(() => {
    getList()
  }, [getList]);

  const processSetIdParentReq = useCallback(() => {
    
    if (!reqList) return;
    // logger("setParent");
    const _reqList: RequestListDTO = CommonTools.addToRequestFilter(
      reqList,
      "idparent",
      idParent
    );
    setReqList(JSON.parse(JSON.stringify(_reqList)));
  }, [idParent]);

  useEffect(() => {
    processSetIdParentReq();
  }, [processSetIdParentReq]);

  useEffect(() => {
    checkIsLoading();
  }, [checkIsLoading]);

  const handleDelete = (item?: T) => {
    if (!item) return;
    if (!item.hasOwnProperty("id")) return;
    if (!item.id) return;
    deleteFun(CommonTools.processObjectField(item, ["id"]), getList, {});
  };

  const _setReqList = (_reqList: RequestListDTO) => {
    _reqList = CommonTools.addToRequestFilter(_reqList, "idparent", idParent);
    setReqList(_reqList);
  };

  const getObject = (id: string, cb: any, cbParams: any) => {
    if (!id) return;
    getObjectFun(id, cb, cbParams);
  };
  const [loadingObject, object, setObject] = useObject<T>(getObject, id, [id]);

  const setParent = (obj: T) => {
    if (!obj) return;
    
    if (!CommonTools.processObjectField(obj, ["id"])) return;
    // logger("setParent", obj);
    setIdParent(CommonTools.processObjectField(obj, ["id"]));
    setObject(obj);
  };

  const goUp = (e:any) => {
    // logger("setParent----------", object);
    CommonTools.goToParent(object, setObject, setIdParent, setId, null);
  };

  return useMemo(
    () => ({
      mainUrl,
      addUrl,
      setListUrl,
      data,
      rows,
      total,
      totalPage,
      responseParams,
      reqList,
      loading,
      deleteOneObject,
      setDeleteOneObject,
      handleDelete,
      setReqList: _setReqList,
      getList,
      listUrl,
      loadingObject,
      object,
      setParent,
      goUp,
      idParent
    }),
    [
      mainUrl,
      addUrl,
      data,
      rows,
      total,
      totalPage,
      responseParams,
      reqList,
      loading,
      deleteOneObject,
      listUrl,
      loadingObject,
      object,
      idParent
    ]
  );
};

export { useListAdminWithChildren };
