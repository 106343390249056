import React from "react";

import { IPage } from "interfaces/page.interface";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box, Grid } from "@mui/material";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";

import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";

import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { FormAbuse } from "components/support/abuse/FormAbuse";
import { AbuseDto } from "dto/support/abuse.dto";
import { AbuseService } from "services/support/abuse.service";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";
import { useAuth } from "hooks/useAuth";
import { DisplayObjectDetails } from "components/elements/display/DisplayObjectDetails";

const service = new AbuseService();
const EditAbuse: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { user } = useAuth();
  const idUser = CommonTools.processObjectField(user, ["id"]);
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };

  const { mainUrl, detailUrl, object, loading, setLoading, id, listUrl } =
    useEditObject<AbuseDto>(mainObject, currentRoute, get);

  const onSubmit = (obj: AbuseDto) => {
    if (!obj || !idUser) return;
    setLoading(true);
    obj.idmanager = idUser;
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!idUser) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        listUrl={listUrl}
      >
        <DisplayObjectDetails
            idParent={object.idparent ?? ""}
            parent={object.parent ?? ""}
          />
        </HeaderEdit>
      <DataDisplayBox>
        <Grid>
          <DetailGrid
            object={object}
            fields={CommonTools.parseDetailFields(
              ["type", "sender", "date", "user_email", "message"],
              AbuseDto.getDetailFields()
            )}
          />
        </Grid>
        <Box mt={3}>
          <FormAbuse
            defaultObj={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { EditAbuse };
