import { Box, Grid, Link, Stack } from "@mui/material";

import { IPage } from "interfaces/page.interface";
import React from "react";

import { HeaderDetail } from "components/elements/detail/HeaderDetail";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";

import { Loading } from "components/elements/loading/Loading";
import { useDetailObject } from "hooks/useDetailObject";

import { AbuseDto } from "dto/support/abuse.dto";
import { AbuseService } from "services/support/abuse.service";

import { useResource } from "hooks/useResource";
import { Status } from "tools/types/status";
import { DisplayObjectDetails } from "components/elements/display/DisplayObjectDetails";

const service = new AbuseService();
const DetailAbuse: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const { mainUrl, editUrl, listUrl, object, loading } =
    useDetailObject<AbuseDto>(mainObject, currentRoute, get);
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetail
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        listUrl={listUrl}
        editUrl={editUrl}
        showEdit={object.status === Status.UNPROCESSED}>
        <Box
          sx={{
            gap: { xs: 2, sm: 0 },
            display: "flex",
            justifyContent: { xs: "center", sm: "start" },
            alignItems: "center",
            flexDirection: { xs: "row", sm: "row" },
            flexWrap: { xs: "wrap", sm: "nowrap" },

            mb: { xs: 2, sm: "auto" },
          }}>
          <Link
            sx={{ overflow: "hidden" }}
            href={AbuseDto.processUrlObjectParent(object)}
            className="detailBtn"
            target="_blank"
            rel="noreferrer">
            {LL("open_reported_object")}
          </Link>
          <DisplayObjectDetails
            idParent={object.idparent ?? ""}
            parent={object.parent ?? ""}
          />
        </Box>
      </HeaderDetail>
      <DataDisplayBox>
        <Grid>
          <DetailGrid object={object} fields={AbuseDto.getDetailFields()} />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { DetailAbuse };
