import { Grid } from "@mui/material";

import { IPage } from "interfaces/page.interface";
import React from "react";

import { HeaderDetail } from "components/elements/detail/HeaderDetail";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";

import { Loading } from "components/elements/loading/Loading";
import { useDetailObject } from "hooks/useDetailObject";


import { RestrictionDto } from "dto/user/restriction.dto";
import {RestrictionService} from "services/user/restriction.service";

const service = new RestrictionService();
const DetailRestriction: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const {
    mainUrl,
    editUrl,
    listUrl,
    object,
    loading,
    
  } = useDetailObject<RestrictionDto>(mainObject, currentRoute, get);

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetail
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        listUrl={listUrl}
        editUrl={editUrl}
        
      />
      <DataDisplayBox>
        <Grid>
          <DetailGrid object={object} fields={RestrictionDto.getDetailFields()} />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { DetailRestriction };