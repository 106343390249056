import { DetailObject } from "interfaces/detailobject.interface";

import React, { useState } from "react";
import { DetailGrid } from "../generalcomponents/DetailGrid";
import { DataDisplayBox } from "./DataDisplayBox";
import { Box, DialogTitle, Grid, Typography } from "@mui/material";
import IService from "interfaces/iservice.interface";
import useObject from "hooks/useObject";
import { ServiceTools } from "tools/utils/servicetools";
import { GeneralDialog } from "../dialog/GeneralDialog";
import { MyButton } from "../button/MyButton";
import { useResource } from "hooks/useResource";
import { CircularLoading } from "../loading/CircularLoading";
import { Height } from "@mui/icons-material";

type BoundaryProps = {
  dto: { getDetailFields(): Array<DetailObject> };
  service: IService;
  id: string;
};

const DisplayObjectDetailsBoundary: React.FC<BoundaryProps> = ({
  dto,
  service,
  id,
}) => {
  const get = (id: string, cb?: any, cbParams?: any) => {
    service.get(id, cb, cbParams);
  };

  const [loading, object] = useObject(get, id, [id]);

  if (loading) return <CircularLoading />;
  if (!object) return null;
  return (
    <Box className="detailsDetail" sx={{ width: "100%" }}>
      <DataDisplayBox>
        <Grid sx={{ width: "100%" }}>
          <DetailGrid object={object} fields={dto.getDetailFields()} />
        </Grid>
      </DataDisplayBox>
    </Box>
  );
};

type Props = {
  idParent: string;
  parent: string;
};
const DisplayObjectDetails: React.FC<Props> = ({ idParent, parent }) => {
  const [open, setOpen] = useState(false);
  const { LL } = useResource();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const service = ServiceTools.getServiceAndType(parent);
  if (!idParent || !parent || !service) return null;

  return (
    <Box>
      <MyButton
        color="primary"
        sx={{ borderRadius: "15px", height: "36px" }}
        cb={handleOpen}>
        <Typography
          sx={{
            textTransform: "lowercase",
            fontWeight: "600",
            marginTop: "2px",
          }}>
          {LL("display_details")}
        </Typography>
      </MyButton>
      <GeneralDialog
        open={open}
        fullWidth
        maxWidth={"md"}
        handleClose={handleClose}>
        <DialogTitle>{LL("details")}</DialogTitle>
        <Box
          sx={{
            overflowX: "scroll",
            // minWidth: 500,
            minHeight: 200,
          }}>
          {open ? (
            <DisplayObjectDetailsBoundary
              dto={service.dto}
              service={service.service}
              id={idParent}
            />
          ) : null}
        </Box>
      </GeneralDialog>
    </Box>
  );
};

export { DisplayObjectDetails };
