import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import SelectOptions from "dto/app/selectoptions.dto";
import { CommonTools } from "tools/utils/commontools";
import { UserDto } from "./user.dto";

export class TeacherDto implements Idto {
  id?: string;
  iduser?: string;
  idtypespecialty?: string;
  idtypetargetgroup?: string;
  idtypeinternetconnection?: string;
  idtypehardware?: string;
  idtypelesson?: string;
  idtypediscipline?: string;
  idtypeoccupation?: string;
  idtypestudies?: string;
  idtypeprofessionalismlevel?: string;
  idtypeexperience?: string;
  bio?: string;
  timetoteachperweek?: string;
  hasaudience?: boolean;
  graduatedinstitution?: string;
  whyliveclass?: string;
  fullurl?: string;
  status?: number;

  idvideolessonexample?: string;
  idvideopresentation?: string;
  idattachmentcv?: string;

  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;

  statusvalidation?: number;
  typecollaboration?: number;
  companyname?: string;
  cui?: string;
  idaddresscontract?: string;
  admin_name?: string;
  admin_surname?: string;
  cnp?: string;
  persona_fiz_name?: string;
  persona_fiz_surname?: string;

  videolessonexample?: any;
  videopresentation?: any;
  attachmentcv?: any;

  user?: UserDto;

  constructor(
    id?: string,
    iduser?: string,
    idtypespecialty?: string,
    idtypetargetgroup?: string,
    idtypeinternetconnection?: string,
    idtypehardware?: string,
    idtypelesson?: string,
    idtypediscipline?: string,
    idtypeoccupation?: string,
    idtypestudies?: string,
    idtypeprofessionalismlevel?: string,
    idtypeexperience?: string,
    bio?: string,
    timetoteachperweek?: string,
    hasaudience?: boolean,
    graduatedinstitution?: string,
    whyliveclass?: string,
    status?: number,
    notificationtype1?: boolean,
    notificationtype2?: boolean,
    notificationtype3?: boolean,
    showprofile?: boolean,
    showcourses?: boolean,
    statusvalidation?: number,
    typecollaboration?: number,
    companyname?: string,
    cui?: string,
    idaddresscontract?: string,
    admin_name?: string,
    admin_surname?: string,
    cnp?: string,
    persona_fiz_name?: string,
    persona_fiz_surname?: string
  ) {
    this.status = status || Status.TEACHER_UNVERIFIED;
    this.id = id || "";
    this.iduser = iduser || "";
    this.idtypespecialty = idtypespecialty || "";
    this.idtypetargetgroup = idtypetargetgroup || "";
    this.idtypeinternetconnection = idtypeinternetconnection || "";
    this.idtypehardware = idtypehardware || "";
    this.idtypelesson = idtypelesson || "";
    this.idtypediscipline = idtypediscipline || "";
    this.idtypeoccupation = idtypeoccupation || "";
    this.idtypestudies = idtypestudies || "";
    this.idtypeprofessionalismlevel = idtypeprofessionalismlevel || "";
    this.idtypeexperience = idtypeexperience || "";
    this.bio = bio || "";
    this.timetoteachperweek = timetoteachperweek || "";
    this.hasaudience = hasaudience || false;
    this.graduatedinstitution = graduatedinstitution || "";
    this.whyliveclass = whyliveclass || "";
    this.notificationtype1 = notificationtype1 || false;
    this.notificationtype2 = notificationtype2 || false;
    this.notificationtype3 = notificationtype3 || false;
    this.showprofile = showprofile || false;
    this.showcourses = showcourses || false;
    this.statusvalidation = statusvalidation || Status.NEED_VALIDATION;
    this.typecollaboration = typecollaboration || Types.TYPE_PERSOANA_FIZICA;
    this.companyname = companyname || "";
    this.cui = cui || "";
    this.idaddresscontract = idaddresscontract || "";
    this.admin_name = admin_name || "";
    this.admin_surname = admin_surname || "";
    this.cnp = cnp || "";
    this.persona_fiz_name = persona_fiz_name || "";
    this.persona_fiz_surname = persona_fiz_surname || "";
  }
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Email",
        value: ["email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Surname",
        value: ["surname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Birthday",
        value: ["birthday_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "bio",
        value: ["bio"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "hasaudience",
        value: ["hasaudience_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "timetoteachperweek",
        value: ["timetoteachperweek"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "graduatedinstitution",
        value: ["graduatedinstitution"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typestudies",
        value: ["typestudies", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "whyliveclass",
        value: ["whyliveclass"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typespecialty",
        value: ["typespecialty", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typetargetgroup",
        value: ["typetargetgroup", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typeinternetconnection",
        value: ["typeinternetconnection", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typehardware",
        value: ["typehardware", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typelesson",
        value: ["typelesson", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typediscipline",
        value: ["typediscipline", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typeoccupation",
        value: ["typeoccupation", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typeprofessionalismlevel",
        value: ["typeprofessionalismlevel", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typeexperience",
        value: ["typeexperience", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typecollaboration",
        value: ["typecollaboration_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typecollaboration_tmp",
        value: ["typecollaboration_tmp_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "statusvalidation",
        value: ["statusvalidation_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "companyname",
        value: ["companyname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "companyname_tmp",
        value: ["companyname_tmp"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "cui",
        value: ["cui"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "cui_tmp",
        value: ["cui_tmp"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "admin_name",
        value: ["admin_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "admin_name_tmp",
        value: ["admin_name_tmp"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "admin_surname",
        value: ["admin_surname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "admin_surname_tmp",
        value: ["admin_surname_tmp"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "cnp",
        value: ["cnp"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "cnp_tmp",
        value: ["cnp_tmp"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "persona_fiz_name",
        value: ["persona_fiz_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "persona_fiz_name_tmp",
        value: ["persona_fiz_name_tmp"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "persona_fiz_surname",
        value: ["persona_fiz_surname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "persona_fiz_surname_tmp",
        value: ["persona_fiz_surname_tmp"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "addresscontract",
        value: ["addresscontract", "fullname"],
      },
      {
        typeField: Types.FIELD_TYPE_FILE,
        label: "CV",
        value: ["attachmentcv"],
      },
      {
        typeField: Types.FIELD_TYPE_VIDEO,
        label: "VideoPresentation",
        value: ["videopresentation"],
      },
      {
        typeField: Types.FIELD_TYPE_VIDEO,
        label: "VideoLessonExample",
        value: ["videolessonexample"],
      },
    ];
  }
  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(
        LL,
        "email",
        undefined,
        false
      ),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "name", undefined, false),
      ComponentCommonTools.columns_StandardColumn(
        LL,
        "surname",
        undefined,
        false
      ),
      ComponentCommonTools.columns_StandardColumn(LL, "timetoteachperweek"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "typediscipline",
        "name"
      ),
      ComponentCommonTools.columns_ConstantColumn(LL, "hasaudience"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("emailsearch", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<TeacherDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(TeacherDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: TeacherDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["email"])
    );
  };

  static getSchemaFields(): Array<string> {
    return [
      "idtypespecialty",
      "idtypetargetgroup",
      "idtypeinternetconnection",
      "idtypehardware",
      "idtypelesson",
      "idtypediscipline",
      "idtypeoccupation",
      "idtypestudies",
      "idtypeprofessionalismlevel",
      "idtypeexperience",
      "bio",
      "timetoteachperweek",
      "hasaudience",
      "graduatedinstitution",
      "whyliveclass",
      "typecollaboration",
      "companyname",
      "cui",
      "idaddresscontract",
      "admin_name",
      "admin_surname",
      "cnp",
      "persona_fiz_name",
      "persona_fiz_surname",
      "idvideolessonexample",
      "idvideopresentation",
      "idattachmentcv",
    ];
  }
}

export class PostTeacherDto implements Idto {
  iduser?: string;
  idtypespecialty?: string;
  idtypetargetgroup?: string;
  idtypeinternetconnection?: string;
  idtypehardware?: string;
  idtypelesson?: string;
  idtypediscipline?: string;
  idtypeoccupation?: string;
  idtypestudies?: string;
  idtypeprofessionalismlevel?: string;
  idtypeexperience?: string;
  bio?: string;
  timetoteachperweek?: string;
  hasaudience?: boolean;
  graduatedinstitution?: string;
  whyliveclass?: string;
  idssociallink?: string[];
  status?: number;
  idsterm?: string[];
  idvideolessonexample?: string;
  idvideopresentation?: string;
  idattachmentcv?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  constructor() {
    this.status = Status.TEACHER_UNVERIFIED;
  }
}

export class TeacherMediaDto implements Idto {
  videolocationlessonexample?: string;
  videolocationpresentation?: string;
  idattachmentcv?: string;
  idteacher?: string;
  files?: File | null;
  fileInfoObj?: any;
  deleteattachmentcv?: boolean;
  deletevideopresentation?: boolean;
  deletevideolessonexample?: boolean;

  constructor(
    idteacher?: string,
    videolocationlessonexample?: string,
    videolocationpresentation?: string,
    idattachmentcv?: string,
    fileInfoObj?: any,
    deleteattachmentcv?: boolean,
    deletevideopresentation?: boolean,
    deletevideolessonexample?: boolean
  ) {
    this.idteacher = idteacher || "";
    this.videolocationlessonexample = videolocationlessonexample || "";
    this.videolocationpresentation = videolocationpresentation || "";
    this.idattachmentcv = idattachmentcv || "";
    this.fileInfoObj = fileInfoObj || null;
    this.deleteattachmentcv = deleteattachmentcv || false;
    this.deletevideopresentation = deletevideopresentation || false;
    this.deletevideolessonexample = deletevideolessonexample || false;
    this.files = null;
  }
}
