import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";

import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import SelectOptions from "dto/app/selectoptions.dto";
import { CommonTools } from "tools/utils/commontools";
import { Status } from "tools/types/status";
import { RouteTools } from "tools/utils/routetools";
import { TeacherDto } from "./teacher.dto";

export class RestrictionDto implements Idto {
  id?: string;
  status?: number;
  action?: number;
  destinatar?: number;
  type?: number;
  field?: string;
  value?: number;

  constructor(
    id?: string,
    status?: number,
    action?: number,
    destinatar?: number,
    type?: number,
    field?: string,
    value?: number
  ) {
    this.id = id || "";
    this.status = status || Status.ACTIVE;
    this.action = action || Types.RESTRICTION_ACTION_TEACHER_VERIFIED;
    this.destinatar = destinatar || Types.RESTRICTION_DESTINATAR_TEACHER;
    this.type = type || Types.RESTRICTION_TYPE_FIELD_REQUIRED;
    this.field = field || "";
    this.value = value || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Action",
        value: ["action_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Destinatar",
        value: ["destinatar_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Field",
        value: ["field"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Value",
        value: ["value"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "action"),
      ComponentCommonTools.columns_ConstantColumn(LL, "destinatar"),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_StandardColumn(LL, "field"),
      ComponentCommonTools.columns_StandardColumn(LL, "value"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(
    data: Array<RestrictionDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(RestrictionDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: RestrictionDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["name"])
    );
  };

  static GA = (key: string): Array<SelectOptions> => {
    const rez: Array<SelectOptions> = [];
    let keys: string[] = [];
    if (key === Types.RESTRICTION_DESTINATAR_TEACHER.toString()) {
      keys = TeacherDto.getSchemaFields();
    }
    keys.forEach((item) => {
      rez.push(new SelectOptions(item, RouteTools._LL(`field_${item}`)));
    });
    return rez;
  };
}
