import React, { createContext, useState, useEffect, useContext } from "react";

import { useCookies } from "react-cookie";

import { Loading } from "components/elements/loading/Loading";

import { useAuth } from "hooks/useAuth";
import LocalStorageTools from "api/localstorage.api";

import { LabelService } from "services/static/label.service";
import { LangService } from "services/static/lang.service";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/commontools";
import { RouteTools } from "tools/utils/routetools";
import { PostLabelDto } from "dto/static/postlabel.dto";
import GeneralRepository from "repositories/general.repository";

import { LabelTypes } from "tools/types/labeltypes";
import { Types } from "tools/types/types";
import { GeneralService } from "services/general.service";
import DateTools from "tools/utils/date.tools";

import { Md5 } from "ts-md5";
import { ServiceTools } from "tools/utils/servicetools";
import { Config } from "tools/utils/config";
import GlobalGeneralService from "services/globalgeneral.service";
import IProvider from "interfaces/provider.interface";
import { SessionStorageTools } from "api/sessionstorage.api";

import { PermissionContext } from "./PermissionProvider";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { TokenDto } from "dto/system/jwt.dto";
import { UserService } from "services/user/user.service";
import { JWTContext } from "./JWTProvider";
import { MyBackdrop } from "components/elements/loading/MyBackdrop";

type Props = {
  LL: (value: string, labelType?: any) => string;
  currentRoute: any;
  setCurrentRoute: any;
  langs: any;
  _idlanguage: any;
  changeLanguage: any;
  saveCache: any;
  getCache: any;
  _getListUrl: any;
  _setListUrl: any;
  setCookieStayOnPage: any;
  getCookieStayOnPage: any;
  _getListSecondUrl: (_default?: string) => string;
  _setListSecondUrl: (value: string) => void;
  _getListThirdUrl: (_default?: string) => string;
  _setListThirdUrl: (value: string) => void;
};
export const ResourceContext = createContext<Props>({
  LL: () => "",
  currentRoute: null,
  setCurrentRoute: () => {},
  langs: [],
  _idlanguage: null,
  changeLanguage: () => {},
  saveCache: () => {},
  getCache: () => {},
  _getListUrl: () => "",
  _setListUrl: () => {},
  setCookieStayOnPage: () => {},
  getCookieStayOnPage: () => {},
  _getListSecondUrl: () => "",
  _setListSecondUrl: () => {},
  _getListThirdUrl: () => "",
  _setListThirdUrl: () => {},
});

const cacheData: any = {};

const labelService: LabelService = new LabelService();
const langService: LangService = new LangService();
const userService = new UserService();
var _labelsSaved: any = [];
var _labelsTmp: any = [];
export const ResourceProvider: React.FC<IProvider> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const {  processToken } = useContext(JWTContext);
  const [loadingAction, setLoadingAction] = useState(false);
  // ------------------------------
  const [_labelType, setLabelType] = useState<any>(false);
  // ------------------------------

  const getDefaultListUrl = (): string => {
    const listUrl = SessionStorageTools.getValue("list_url");
    if (listUrl) return listUrl;
    else return "";
  };
  const getDefaultListSecondUrl = (): string => {
    const listUrl = SessionStorageTools.getValue("list_second_url");
    if (listUrl) return listUrl;
    else return "";
  };
  const getDefaultListThirdUrl = (): string => {
    const listUrl = SessionStorageTools.getValue("list_third_url");
    if (listUrl) return listUrl;
    else return "";
  };
  // ------------------------------
  const [listUrl, setListUrl] = useState(getDefaultListUrl());
  const [listSecondUrl, setListSecondUrl] = useState(getDefaultListSecondUrl());
  const [listThirdUrl, setListThirdUrl] = useState(getDefaultListThirdUrl());
  // ------------------------------

  // ------------------------------

  var _labels = LocalStorageTools.getObject("_llabels");
  var _localLabelsHash = LocalStorageTools.getValue("_llhash");
  // const [_localLabelsHash, setLocalLabelsHash] = useState("-1");
  const [_serverLabelsHash, setServerLabelsHash] = useState("-1");
  const [_isLabelsLoaded, setIsLabelsLoaded] = useState(false);
  // const [_labels, setLabels] = useState([]);
  // var _labels = [];
  // ------------------------------

  const { user, roles, usersettings, updateUserSettings } = useAuth();

  const [currentRoute, setCurrentRoute] = useState<any>(false);
  const { getPermission } = useContext(PermissionContext);
  // ------------------------------

  // ------------------------------

  // ------------------------------
  var _langs = LocalStorageTools.getObject("_llangs");
  var _localLangsHash = LocalStorageTools.getValue("_llanghash");
  // const [_localLangsHash, setLocalLangsHash] = useState("-1");
  const [_serverLangsHash, setServerLangsHash] = useState("-1");
  const [_isLangsLoaded, setIsLangsLoaded] = useState(false);
  const [langs, setLangs] = useState([]);

  // ------------------------------
  const [_idlanguage, setIdLanguage] = useState<any>(false);

  // ------------------------------
  const [isLoading, setIsLoading] = useState(true);

  const setCookieStayOnPage = (mainObject: string, value: any) => {
    if (value) {
      setCookie(mainObject + "_stayonpage", true, {
        path: "/",

        expires: DateTools.getDate(Config.COOKIE_EXPIRES),
      });
    } else {
      removeCookie(mainObject + "_stayonpage", {
        path: "/",
      });
    }
  };

  const getCookieStayOnPage = (mainObject: string) => {
    const value = cookies[mainObject + "_stayonpage"] ?? false;
    return value;
  };

  // ----------------------------------------

  const getCacheIdentifier = (key: string) => {
    let k = _idlanguage.toString();
    k += "_" + JSON.stringify(key);

    return Md5.hashStr(k);
  };

  const saveCache = (data: any, key: string) => {
    const identifier = getCacheIdentifier(key);

    cacheData[identifier] = data;
  };

  const getCache = (key: any) => {
    const identifier = getCacheIdentifier(key);

    if (cacheData[identifier] != undefined) return cacheData[identifier];

    return false;
  };
  // ----------------------------------------

  // ----------------------------------------
  useEffect(() => {
    GeneralRepository.setIdLanguage(_idlanguage);
    RouteTools.setLL(LL);
    Status.setLL(LL);
    LabelTypes.setLL(LL);
    Types.setLL(LL);
    GeneralService.setLL(LL);
    RouteTools.setHasAccess(hasAccess);
    RouteTools.setCheckUser(checkUser);
    GlobalGeneralService.setLL(LL);
    ServiceTools.setSaveCache(saveCache);
    ServiceTools.setGetCache(getCache);
  }, [user, roles, usersettings, currentRoute, isLoading, _idlanguage]);

  // ----------------------------------------
  useEffect(() => {
    // loadLocalLabelsHash();
    loadServerLabelsHash();

    // loadLocalLangsHash();
    loadServerLangsHash();

    if (!_labelType) {
      let labelType = Config.LABEL_TYPE;
      labelType = labelType.toString();
      setLabelType(labelType);
    }
  }, []);

  // ----------------------------------------
  useEffect(() => {
    checkLabelsHash();
  }, [_localLabelsHash, _serverLabelsHash]);

  // const loadLocalLabelsHash = () => {
  //   const _llhash = LocalStorageTools.getValue("_llhash");
  //   setLocalLabelsHash(_llhash);
  // };

  const handleLoadServerLabelsHash = (result: any) => {
    setServerLabelsHash(result.obj.hash);
  };

  const loadServerLabelsHash = () => {
    labelService.getServerLabelsHash(handleLoadServerLabelsHash);
  };

  const checkLabelsHash = () => {
    if (_isLabelsLoaded) return;

    if (_localLabelsHash == "-1") return;
    if (_serverLabelsHash == "-1") return;

    if (_localLabelsHash == _serverLabelsHash) {
      loadLabelsLocal();
    } else {
      loadLabelsServer();
    }
  };

  const loadLabelsLocal = () => {
    // const _llabels = LocalStorageTools.getObject("_llabels");
    // _labels  = LocalStorageTools.getObject("_llabels");
    setIsLabelsLoaded(true);
    // setLabels(_llabels);
  };

  const handleLoadLabelsServer = (result: any) => {
    _labelsTmp = [..._labelsTmp, ...result.objects];
    // // logger("_labelsTmp_labelsTmp_labelsTmp_labelsTmp", _labelsTmp);
    // const _tl = Array.from(_labelsTmp).concat(result.objects);
    // _labelsTmp = _tl;
    if (result.requestinfo.page < result.totalpages) {
      const np = result.requestinfo.page + 1;
      loadLabelsServer(np);
    } else {
      _labels = _labelsTmp;
      LocalStorageTools.saveValue("_llhash", _serverLabelsHash);
      LocalStorageTools.saveObject("_llabels", _labelsTmp);
    }
    // _labels = result.objects;
    // LocalStorageTools.saveValue("_llhash", _serverLabelsHash);
    // LocalStorageTools.saveObject("_llabels", result.objects);
    // setIsLabelsLoaded(true);

    // setLabels(result.objects);
  };

  const loadLabelsServer = (p?: number) => {
    p = p ?? 1;
    var labelType = Config.LABEL_TYPE;

    const cbP = {};
    const data = new RequestListDTO();
    data.filters = [];
    data.page = p;
    data.onpage = 100;
    // data.onpage = 9999999;
    const f = new RequestFilterDTO();
    f.field = "type";
    f.values = [];
    f.values.push(labelType.toString());
    f.values.push(LabelTypes.LABEL_MESSAGE.toString());
    f.values.push(LabelTypes.LABEL_VALIDATION.toString());
    data.filters.push(f);

    labelService.getList(handleLoadLabelsServer, cbP, data);
  };

  // ----------------------------------------
  useEffect(() => {
    checkLangsHash();
  }, [_localLangsHash, _serverLangsHash]);

  // const loadLocalLangsHash = () => {
  //   const _llanghash = LocalStorageTools.getValue("_llanghash");
  //   setLocalLangsHash(_llanghash);
  // };

  const handleLoadServerLangsHash = (result: any) => {
    setServerLangsHash(result.obj.hash);
  };

  const loadServerLangsHash = () => {
    langService.getServerLangsHash(handleLoadServerLangsHash, {});
  };

  const checkLangsHash = () => {
    if (_isLangsLoaded) return;

    if (_localLangsHash == "-1") return;
    if (_serverLangsHash == "-1") return;

    if (_localLangsHash == _serverLangsHash) {
      loadLangsLocal();
    } else {
      loadLangsServer();
    }
  };

  const loadLangsLocal = () => {
    const _llangs = LocalStorageTools.getObject("_llangs");
    setIsLangsLoaded(true);
    setLangs(_llangs);
  };

  const handleLoadLangsServer = (result: any) => {
    _langs = result.objects;
    LocalStorageTools.saveValue("_llanghash", _serverLangsHash);
    LocalStorageTools.saveObject("_llangs", result.objects);
    setIsLangsLoaded(true);
    // logger("handleLoadLangsServer", result.objects);
    setLangs(result.objects);
  };

  const loadLangsServer = () => {
    const cbP = {};
    const data = new RequestListDTO();
    data.filters = [];
    const f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    data.filters.push(f);

    langService.getList(handleLoadLangsServer, cbP, data);
  };

  // ----------------------------------------
  useEffect(() => {
    checkIsLoading();
  }, [_isLabelsLoaded, _isLangsLoaded, _labelType]);

  const checkIsLoading = () => {
    if (!isLoading) return;

    // if (!_isPermissionsLoaded) return;
    // if (!_isLabelsLoaded) return;
    if (!_isLangsLoaded) return;
    if (!_labelType) return;

    setIsLoading(false);
  };

  // ----------------------------------------

  // ----------------------------------------
  useEffect(() => {
    if (!_isLangsLoaded) return;
    processLanguage();
  }, [_isLangsLoaded]);

  const changeLanguage = (idl: string) => {
    if (!idl) return;
    setIdLanguage(idl);
    setLoadingAction(true);
    userService.changeLanguage(idl, processResultLanguage, {});
  };

  

  const processResultLanguage = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    const token = CommonTools.processObjectField(result, [
      "obj",
      "jwttoken",
      "token",
    ]);
    processToken(token);
    setIdLanguage(TokenDto.getLanguage(token));
    setLoadingAction(false);
    GeneralRepository.reloadFunction();
  };


  const processLanguage = () => {
    if (
      usersettings != undefined &&
      usersettings != null &&
      usersettings.idlanguage != undefined &&
      usersettings.idlanguage != null &&
      usersettings.idlanguage
    ) {
      setIdLanguage(usersettings.idlanguage);
      return;
    }

    if (cookies.idlanguage) {
      setIdLanguage(cookies.idlanguage);
      return;
    }

    const lobj = getDefalutLanguage();

    if (lobj != null) {
      setIdLanguage(lobj.id);
    }
  };

  const getDefalutLanguage = () => {
    var lcode = Config.DEFAULT_LANGUAGE;
    lcode = lcode.toString().toLowerCase();

    var rez = null;
    for (var i in _langs) {
      if (_langs[i].cod2.toString().toLowerCase() != lcode) continue;

      rez = _langs[i];
      break;
    }

    return rez;
  };

  // ----------------------------------------

  // ----------------------------------------
  const SaveLabel = (
    identifier: string,
    preparedIdentifier: string,
    labelType: any
  ) => {
    // logger("SaveLabelSaveLabelSaveLabel 1111", identifier, _labelsSaved);
    if (_labelsSaved.indexOf(identifier) !== -1) return;
    _labelsSaved.push(identifier);

    // logger("SaveLabelSaveLabelSaveLabel 22222", identifier, _labelsSaved);

    labelType = labelType != undefined ? labelType : _labelType;

    const obj = new PostLabelDto();
    obj.identifier = preparedIdentifier;
    obj.type = labelType;
    obj.status = Status.ACTIVE;
    obj.value = identifier;
    obj.idlanguage = _idlanguage;
    obj._nonupdate = "1";

    if (!Array.isArray(_labels)) _labels = [];
    _labels.push(obj);

    labelService.add(false, {}, obj);
  };

  const LL = (identifier: string, labelType?: any) => {
    labelType = labelType != undefined ? labelType : _labelType;

    const preparedIdentifier = CommonTools.prepareLabeldentifier(identifier);

    if (!_idlanguage) return identifier;

    let value = "";
    let founded = false;

    for (var i in _labels) {
      if (!_labels[i]) continue;
      if (_labels[i].identifier != preparedIdentifier) continue;
      if (_labels[i].type.toString() != labelType.toString()) continue;
      founded = true;

      for (var j in _labels[i].allvalues) {
        if (
          _labels[i].allvalues[j].idlanguage.toString() !=
          _idlanguage.toString()
        ) {
          continue;
        }
        value = _labels[i].allvalues[j].value;

        break;
      }

      value = value != "" ? value : _labels[i].value;

      break;
    }

    if (founded) {
      value = value ?? identifier;
      return value;
    }

    SaveLabel(identifier, preparedIdentifier, labelType);
    return identifier;
  };

  // ----------------------------------------

  const hasAccess_CheckRoles = (permissionsObj: any) => {
    if (!permissionsObj) return true;
    if (!permissionsObj.acceptedroles) return true;
    if (!permissionsObj.acceptedroles) return true;

    if (!roles) return false;
    if (!roles.length) return false;

    let rez = false;
    for (let i in permissionsObj.acceptedroles) {
      if (roles.indexOf(permissionsObj.acceptedroles[i]) != -1) {
        rez = true;
        break;
      }
    }

    return rez;
  };

  const hasAccess = (key: string) => {
    // temporar:
    // return true;

    const permissionsObj = getPermission(key);
    if (!permissionsObj) return true;

    return hasAccess_CheckRoles(permissionsObj);
  };

  const checkUser = () => {
    if (!user) return false;

    return true;
  };
  // ----------------------------------------

  const _getListUrl = (_default?: string) => {
    if (!_default) _default = "";
    let rez = listUrl;
    rez = rez != undefined && rez != "" ? rez : _default;
    return rez;
  };
  const _setListUrl = (value: string) => {
    // if (value == undefined || value == '') return;
    setListUrl(value);
    SessionStorageTools.saveValue("list_url", value);
  };

  const _getListSecondUrl = (_default?: string) => {
    if (!_default) _default = "";
    let rez = listSecondUrl;
    rez = rez != undefined && rez != "" ? rez : _default;
    return rez;
  };
  const _setListSecondUrl = (value: string) => {
    // if (value == undefined || value == '') return;
    setListSecondUrl(value);
    SessionStorageTools.saveValue("list_second_url", value);
  };


  const _getListThirdUrl = (_default?: string) => {
    if (!_default) _default = "";
    let rez = listThirdUrl;
    rez = rez != undefined && rez != "" ? rez : _default;
    return rez;
  };
  const _setListThirdUrl = (value: string) => {
    // if (value == undefined || value == '') return;
    setListThirdUrl(value);
    SessionStorageTools.saveValue("list_third_url", value);
  };



  const value = {
    LL,
    currentRoute,
    setCurrentRoute,
    langs,
    _idlanguage,
    changeLanguage,
    saveCache,
    getCache,
    _getListUrl,
    _setListUrl,
    setCookieStayOnPage,
    getCookieStayOnPage,
    _setListSecondUrl,
    _getListSecondUrl,
    _setListThirdUrl,
    _getListThirdUrl,
  };

  return isLoading ? (
    <Loading />
  ) : (
    <ResourceContext.Provider value={value}>
      {children}
      <MyBackdrop loading={loadingAction} />
    </ResourceContext.Provider>
  );
};
