import { Box } from "@mui/material";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { SiteNotifyTemplateDto } from "dto/notification/sitenotifytemplate.dto";
import { useForm } from "hooks/useForm";

import React from "react";

import RequiredValidator from "validators/required.validator";

import { useResource } from "hooks/useResource";
import MyTextField from "components/elements/form/mytextfield";
import { LanguageSelect } from "components/static/language/newLanguageSelect";
import { MyCKEditorWithParams } from "components/elements/ckeditor/MyCKEditorWithParams";
import { MyButton } from "components/elements/button/MyButton";
import { StaticSelect } from "components/elements/select/newSelect/StaticSelect";
import { Types } from "tools/types/types";

type Props = {
  defaultObject: SiteNotifyTemplateDto;
  onSubmit: (data: SiteNotifyTemplateDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormSiteNotifyTemplate: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , _vresults, setLanguage] =
    useForm<SiteNotifyTemplateDto>(
      defaultObject,
      RequiredValidator.getValidators(["identifier", "message"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="identifier"
            _label={LL("field_identifier")}
            setObj={setObjField}
            value={obj.identifier}
            _vresults={_vresults}
          />
        </Box>

        {forAdd && (
          <Box mt={3}>
            <LanguageSelect
              value={obj.idlanguage ?? ""}
              setValue={setLanguage}
            />
          </Box>
        )}
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>
        <Box mt={3}>
          <StaticSelect
            setObjField={setObjField}
            value={obj.destinationtype}
            service={Types}
            field={"destinationtype"}
            label="destinationtype"
            type="notificationdestinationtype"
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="title"
            _label={LL("field_title")}
            setObj={setObjField}
            value={obj.title}
            _vresults={_vresults}
          />
        </Box>
        <Box mt={3}>
          <MyCKEditorWithParams
            field="message"
            setObjectField={setObjField}
            data={obj.message}
            params={obj.parameters ?? []}
          />
        </Box>
        <Box mt={3}>
          <MyButton
            type="submit"
            disabled={loading || disabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormSiteNotifyTemplate };
