import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { RestrictionRepository } from "repositories/user/restriction.repository";

import RequestListDTO from "dto/app/requestlist.dto";

export class RestrictionService
  extends GeneralService<RestrictionRepository>
  implements IService
{
  constructor() {
    super(
      new RestrictionRepository(),
      [
        { key: "status", type: "status" },
        { key: "type", type: "types" },
        { key: "action", type: "types" },
        { key: "destinatar", type: "types" },
      ],
      "_id"
    );
  }
  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    return defaultRequestList;
  }
}
