import { Box } from "@mui/material";
import MyTextField from "components/elements/form/mytextfield";
import { CourseTypeSelect } from "components/elements/select/CourseTypeSelect";

import { MyButton } from "components/elements/button/MyButton";
import { CourseSettingsDto } from "dto/course/coursesettings.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: CourseSettingsDto;
  onSubmit: (data: CourseSettingsDto) => void;
  loading: boolean;
};
const FormCourseSettings: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField,,vresults] = useForm<CourseSettingsDto>(
    defaultObject,
    RequiredValidator.getValidators(["idtypecourse"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <CourseTypeSelect
            value={obj.idtypecourse}
            setObjectField={setObjField}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="password"
            name="password"
            variant="outlined"
            color="primary"
            _label={LL("password")}
            size="small"
            value={obj.password}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={2}
            fullWidth
            id="minnumberofstudents"
            name="minnumberofstudents"
            variant="outlined"
            color="primary"
            _label={LL("min_number_of_students")}
            size="small"
            value={obj.minnumberofstudents}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={3}
            fullWidth
            id="maxnumberofstudents"
            name="maxnumberofstudents"
            variant="outlined"
            color="primary"
            _label={LL("Max_number_of_students")}
            size="small"
            value={obj.maxnumberofstudents}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyButton
            tabIndex={4}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormCourseSettings };
