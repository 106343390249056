import { HeaderList } from "components/elements/list/HeaderList";
import { useListAdmin } from "hooks/useListAdmin";
import { NotificationDto } from "dto/notification/notification.dto";
import MyTable from "components/elements/tabeltools/MyTable";
import {NotificationService} from "services/notification/notification.service";
import RequestListDTO from "dto/app/requestlist.dto";
import { useResource } from "hooks/useResource";
import React from "react";
import { IPage } from "interfaces/page.interface";
import { Box, Stack, Typography } from "@mui/material";
import { SendTestEmail } from "components/notification/sendtest/SendTestEmail";

const service = new NotificationService();

const Notification: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const getListFun = (cb: any, cbParams: any, reqList: any) => {
    service.getList(cb, cbParams, reqList);
  };
  const getRequestListFun = (req: RequestListDTO) => {
    return service.getDefaultRequestList(req);
  };
  const deleteFun = (id: string, cb?: any, params?: any) => {
    service.delete(id, cb, params);
  };
  const {
    addUrl,
    mainUrl,
    rows,
    total,
    totalPage,
    reqList,
    setReqList,
    loading,getList,
  } = useListAdmin<NotificationDto>(
    mainObject,
    currentRoute,
    getListFun,
    getRequestListFun,
    deleteFun
  );
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!reqList) return null;
  return (
    <React.Fragment>
      <HeaderList
        mainObject={mainObject}
        mainUrl={mainUrl}
        addUrl={addUrl}
        showAddButton={false}
      />
      <Box sx={{ mb: 4 }}>
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <Typography variant="h5">{LL("Actions")}</Typography>
          <SendTestEmail />
        </Stack>
      </Box>
      <MyTable getList={getList}
        _columns={NotificationDto.getColumns(LL, mainObject)}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        _tbFilterList={NotificationDto.getFilters()}
        setReqList={setReqList}
        loading={loading}
        currentRoute={currentRoute}
        mainObject={mainObject}
        fieldToShowOnDelete="id"
      />
    </React.Fragment>
  );
};

export { Notification };
