import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/elements/form/mytextfield";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { AbuseDto } from "dto/support/abuse.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: AbuseDto;
  onSubmit: (obj: AbuseDto) => void;
  loading: boolean;
};

const FormAbuse: React.FC<Props> = ({ defaultObj, onSubmit, loading }) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, , vresults] = useForm<AbuseDto>(
    defaultObj,
    RequiredValidator.getValidators(["response", "status"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <StatusSelect
            value={obj.status}
            setObjField={setObjField}
            typeStatus="requests"
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={2}
            fullWidth
            id="response"
            name="response"
            minRows={4}
            color="primary"
            _label={LL("response")}
            size="small"
            field="response"
            value={obj.response}
            setObj={setObjField}
            multiline
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormAbuse };
