import { LabelTypes } from "tools/types/labeltypes";
import SelectOptions from "dto/app/selectoptions.dto";

export class Status {
  static _LL: any = false;

  static setLL(_l: any): any {
    this._LL = _l;
  }

  // -------------------------------------

  public static ACTIVE = 1;
  public static INACTIVE = 2;
  public static CLOSED = 3;
  public static TEMPORARY = 4;
  public static ACCOUNT_REGISTER_SOCIAL = 5;
  public static UNPROCESSED = 6;
  public static PLANNED = 7;
  public static NEED_VALIDATION = 8;
  public static VALIDATED = 9;
  public static VALIDATION_REJECTED = 10;
  public static TEACHER_PRO = 11;
  public static TEACHER_VERIFIED = 12;
  public static TEACHER_UNVERIFIED = 13;

  public static COURSE_DRAFT = 21;
  public static COURSE_UNVERIFIED = 22;
  public static COURSE_TO_BE_REVERIFIED = 23;
  public static COURSE_PUBLISHED = 26;
  public static COURSE_INCOMPLETE = 27;
  public static COURSE_STARTED = 28;
  public static COURSE_ON_GOING = 29;
  public static COURSE_FINISHED = 24;
  public static COURSE_DELISTED = 25;

  public static REPORTED = 31;

  public static PAYMENT_PAID = 41;
  public static PAYMENT_UNPAID = 42;
  public static PAYMENT_REFUND = 43;
  public static PAYMENT_CANCEL = 44;

  public static ENR_UN_FINISHED = 51;
  public static ENR_NEED_START_TEST = 52;
  public static ENR_PASSED_START_TEST = 53;
  public static ENR_NEED_CHECKOUT = 54;
  public static ENR_COURSE_READY = 55;
  public static ENT_COURSE_STARTED = 56;
  public static ENR_COURSE_FINISHED = 57;
  public static ENR_NEED_END_TEST = 58;
  public static ENR_PASSED_END_TEST = 59;
  public static ENR_NEED_CERTIFICATE = 60;
  public static ENR_WITH_CERTIFICATE = 61;
  public static ENR_ARCHIVED = 62;
  public static ENR_CANCELED = 63;

  public static TEST_DID_NOT_START = 71;
  public static TEST_STARTED = 72;
  public static TEST_FINISHED = 73;
  public static TEST_ERROR = 74;
  public static TEST_SUCCESS = 75;
  // -------------------------------------

  public static LESSON_ERR_CURRENT = 81;
  public static LESSON_ERR_FINISHED = 82;
  public static LESSON_ERR_PASSED_TEST = 83;
  public static LESSON_ERR_NEED_TEST = 84;

  public static NOTIFICATION_NEW = 91;
  public static NOTIFICATION_UNFINISHED = 92;
  public static NOTIFICATION_READ = 93;
  public static NOTIFICATION_SENDED = 94;
  public static NOTIFICATION_READY = 95;

  public static REQUEST_ACCEPTED = 101;
  public static REQUEST_REJECTED = 102;
  public static REQUEST_MODIFIED = 103;

  public static OPEN_TICKET = 111;
  public static REOPEN_TICKET = 112;

  public static READ = 121;
  public static UNREAD = 122;

  public static _constants: { [key: number]: string } = {
    1: "ACTIVE",
    2: "INACTIVE",
    3: "CLOSED",
    4: "TEMPORARY",
    5: "ACCOUNT_REGISTER_SOCIAL",
    6: "UNPROCESSED",
    7: "PLANNED",
    8: "NEED_VALIDATION",
    9: "VALIDATED",
    10: "VALIDATION_REJECTED",

    11: "TEACHER_PRO",
    12: "TEACHER_VERIFIED",
    13: "TEACHER_UNVERIFIED",

    21: "COURSE_DRAFT",
    22: "COURSE_UNVERIFIED",
    23: "COURSE_TO_BE_REVERIFIED",
    24: "COURSE_FINISHED",
    25: "COURSE_DELISTED",
    26: "COURSE_PUBLISHED",
    27: "COURSE_INCOMPLETE",
    28: "COURSE_STARTED",
    29: "COURSE_ON_GOING",

    31: "REPORTED",

    41: "PAYMENT_PAID",
    42: "PAYMENT_UNPAID",
    43: "PAYMENT_REFUND",
    44: "PAYMENT_CANCEL",

    51: "ENR_UN_FINISHED",
    52: "ENR_NEED_START_TEST",
    53: "ENR_PASSED_START_TEST",
    54: "ENR_NEED_CHECKOUT",
    55: "ENR_COURSE_READY",
    56: "ENT_COURSE_STARTED",
    57: "ENR_COURSE_FINISHED",
    58: "ENR_NEED_END_TEST",
    59: "ENR_PASSED_END_TEST",
    60: "ENR_NEED_CERTIFICATE",
    61: "ENR_WITH_CERTIFICATE",
    62: "ENR_ARCHIVED",
    63: "ENR_CANCELED",

    71: "TEST_DID_NOT_START",
    72: "TEST_STARTED",
    73: "TEST_FINISHED",

    91: "NOTIFICATION_NEW",
    92: "NOTIFICATION_UNFINISHED",
    93: "NOTIFICATION_READ",
    94: "NOTIFICATION_SENDED",
    95: "NOTIFICATION_READY",

    101: "REQUEST_ACCEPTED",
    102: "REQUEST_REJECTED",
    103: "REQUEST_MODIFIED",

    111: "OPEN_TICKET",
    112: "REOPEN_TICKET",

    121: "READ",
    122: "UNREAD",
  };

  // -------------------------------------

  public static LL = (key: any) => {
    const k: number = parseInt(key);
    if (!this._constants.hasOwnProperty(k)) return null;
    return this._LL(
      "Status_" + this._constants[k].toString(),
      LabelTypes.LABEL_MESSAGE
    );
  };

  // -------------------------------------

  public static GA_KEYS = (key: string): Array<number> => {
    var rez: Array<number> = [];

    if (key === "clasificator") {
      rez = [this.ACTIVE, this.INACTIVE];
    }

    if (key === "requests") {
      rez = [this.UNPROCESSED, this.CLOSED];
    }

    if (key === "email") {
      rez = [this.ACTIVE, this.INACTIVE];
    }
    if (key === "password") {
      rez = [
        this.ACTIVE,
        this.INACTIVE,
        this.TEMPORARY,
        this.ACCOUNT_REGISTER_SOCIAL,
      ];
    }

    if (key === "teacher") {
      rez = [this.TEACHER_PRO, this.TEACHER_VERIFIED, this.TEACHER_UNVERIFIED];
    }

    if (key === "course") {
      rez = [
        this.COURSE_DRAFT,
        this.COURSE_UNVERIFIED,
        this.COURSE_TO_BE_REVERIFIED,
        this.COURSE_PUBLISHED,
        this.COURSE_INCOMPLETE,
        this.COURSE_STARTED,
        this.COURSE_ON_GOING,
        this.COURSE_FINISHED,
        this.COURSE_DELISTED,
      ];
    }

    if (key === "testimonial") {
      rez = [this.ACTIVE, this.INACTIVE, this.REPORTED];
    }

    if (key === "payment") {
      rez = [
        this.PAYMENT_PAID,
        this.PAYMENT_UNPAID,
        this.PAYMENT_REFUND,
        this.PAYMENT_CANCEL,
      ];
    }

    if (key === "enrollment") {
      rez = [
        this.ENR_UN_FINISHED,
        this.ENR_NEED_START_TEST,
        this.ENR_PASSED_START_TEST,
        this.ENR_NEED_CHECKOUT,
        this.ENR_COURSE_READY,
        this.ENT_COURSE_STARTED,
        this.ENR_COURSE_FINISHED,
        this.ENR_NEED_END_TEST,
        this.ENR_PASSED_END_TEST,
        this.ENR_NEED_CERTIFICATE,
        this.ENR_WITH_CERTIFICATE,
        this.ENR_ARCHIVED,
        this.ENR_CANCELED,
      ];
    }

    if (key === "lessonenrollment") {
      rez = [
        this.LESSON_ERR_CURRENT,
        this.LESSON_ERR_FINISHED,
        this.LESSON_ERR_PASSED_TEST,
        this.LESSON_ERR_NEED_TEST,
      ];
    }

    if (key === "testresult") {
      rez = [
        this.TEST_DID_NOT_START,
        this.TEST_STARTED,
        this.TEST_ERROR,
        this.TEST_SUCCESS,
      ];
    }

    if (key === "test") {
      rez = [
        this.TEST_DID_NOT_START,
        this.TEST_STARTED,
        this.TEST_FINISHED,
        this.TEST_ERROR,
        this.TEST_SUCCESS,
      ];
    }

    if (key === "notification") {
      rez = [
        this.NOTIFICATION_NEW,
        this.NOTIFICATION_UNFINISHED,
        this.NOTIFICATION_READ,
        this.NOTIFICATION_SENDED,
        this.NOTIFICATION_READY,
      ];
    }

    if (key === "teacherrequest") {
      rez = [
        this.UNPROCESSED,
        this.REQUEST_ACCEPTED,
        this.REQUEST_REJECTED,
        this.REQUEST_MODIFIED,
      ];
    }

    if (key === "ticket") {
      rez = [this.OPEN_TICKET, this.CLOSED, this.REOPEN_TICKET];
    }

    if (key === "ticketmessage") {
      rez = [this.READ, this.UNREAD];
    }

    if (key === "teachervalidation") {
      rez = [this.NEED_VALIDATION, this.VALIDATED, this.VALIDATION_REJECTED];
    }

    return rez;
  };

  public static GA = (
    key: string,
    emptyVal?: boolean,
    emptyLabel?: string
  ): Array<SelectOptions> => {
    const keys = this.GA_KEYS(key);

    const rez: Array<SelectOptions> = [];

    if (emptyVal) {
      const t = new SelectOptions();
      t.value = "";
      t.label = emptyLabel ?? Status._LL("all");
      rez.push(t);
    }

    keys.forEach((item) => {
      const t = new SelectOptions();
      t.value = item;
      t.label = Status.LL(item);
      rez.push(t);
    });

    return rez;
  };

  // -------------------------------------
}
