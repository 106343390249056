import { Box } from "@mui/material";

import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { RestrictionDto } from "dto/user/restriction.dto";
import { StaticSelect } from "components/elements/select/newSelect/StaticSelect";
import { Types } from "tools/types/types";
import { Status } from "tools/types/status";

type Props = {
  defaultObject: RestrictionDto;
  onSubmit: (data: RestrictionDto) => void;
  loading: boolean;
};
const FormRestriction: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<RestrictionDto>(
    defaultObject,
    RequiredValidator.getValidators(["field"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <StaticSelect
            setObjField={setObjField}
            value={obj.status}
            service={Status}
            field={"status"}
            label="status"
            type="clasificator"
          />
        </Box>
        <Box mt={3}>
          <StaticSelect
            setObjField={setObjField}
            value={obj.action}
            service={Types}
            field={"action"}
            label="action"
            type="restrictionaction"
          />
        </Box>
        <Box mt={3}>
          <StaticSelect
            setObjField={setObjField}
            value={obj.destinatar}
            service={Types}
            field={"destinatar"}
            label="destinatar"
            type="restrictiondestinatar"
          />
        </Box>
        <Box mt={3}>
          <StaticSelect
            setObjField={setObjField}
            value={obj.type}
            service={Types}
            field={"type"}
            label="type"
            type="restrictiontype"
          />
        </Box>
        <Box mt={3}>
          <StaticSelect
            setObjField={setObjField}
            value={obj.field}
            service={RestrictionDto}
            field={"field"}
            label="field"
            type={obj.destinatar ? obj.destinatar.toString() : ""}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="value"
            name="value"
            variant="outlined"
            color="primary"
            _label={LL("value")}
            size="small"
            value={obj.value}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormRestriction };
